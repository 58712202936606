import Vue from 'vue'
import App from './App.vue'
import '@/styles/main.css' // global css
import router from './router'
//引入lib-flexible 用于设置rem基准值
import "lib-flexible/flexible"

import {Notify, Row, Col, Tag, Toast, Loading,Image as VanImage  } from 'vant';

Vue.use(VanImage);
Vue.use(Notify)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tag);
Vue.use(Toast);
Vue.use(Loading);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
