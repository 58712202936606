import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '/',
        name: 'home',
        component: () =>
            import ('@/views/home'),
        meta: {
            title: '高速公路收费标准化系统 - 初始重置登陆'
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () =>
            import ('@/views/reset-password/index'),
        meta: {
            title: '高速公路收费标准化系统 - 密码重置'
        }
    },
    {
        path: '/initialization-login',
        name: 'initialization-login',
        component: () =>
            import ('@/views/initialization-login/index'),
        meta: {
            title: '高速公路收费标准化系统 - 初始重置登陆'
        }
    },
    {
        path: '/personal-info',
        name: 'personal-info',
        component: () =>
            import ('@/views/personal-info/index'),
        meta: {
            title: '高速公路收费标准化系统 - 绑定微信'
        }
    },
    {
        path: '/form-complete',
        name: 'form-complete',
        component: () =>
            import ('@/views/form-complete/index'),
        meta: {
            title: '高速公路收费标准化系统 - 完成'
        }
    },
    {
        path: '/activity-home',
        name: 'activity-home',
        component: () =>
            import ('@/views/activity-home/index'),
        meta: {
            title: '高速公路收费标准化系统 - 完成'
        }
    },
    {
        path: '/study-entrance',
        name: 'study-entrance',
        component: () =>
            import ('@/views/study-entrance/index'),
        meta: {
            title: '高速公路收费标准化系统 - 完成'
        }
    },
    {
        path: '/study-file',
        name: 'study-file',
        component: () =>
            import ('@/views/study-file/index'),
        meta: {
            title: '高速公路收费标准化系统 - 完成'
        }
    },
    {
        path: '/study-detail',
        name: 'study-detail',
        component: () =>
            import ('@/views/study-detail/index'),
        meta: {
            title: '高速公路收费标准化系统 - 完成'
        }
    },
    // {
    //     path: '/gologin',
    //     name: 'gologin',
    //     component: () =>
    //         import ('@/views/login/index'),
    //     meta: {
    //         title: '登录'
    //     }
    // },
    {
        path: '/404',
        component: () =>
            import ('@/views/404'),
        meta: {
            title: '404'
        }
    },
    { path: '*', redirect: '/404', hidden: true }
]


const createRouter = () => new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const router = createRouter()

// router.beforeEach((to, from, next) => {
//   console.log('beforeEach', to, from)
//   if (to.meta && to.meta.title) {
//     document.title = to.meta.title
//   } else {
//     document.title = 'default title'
//   }
//   next()
// })

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router